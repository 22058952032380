import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import { useRef } from "react";
import { rightImg, watchImg } from "../utils";
import VideoCarousel from "./video-carousel";

export default function Highlights() {
  const headingRef = useRef();
  useGSAP(() => {
    gsap.to(headingRef.current, {
      scrollTrigger: {
        trigger: "#title",
      },
      opacity: 1,
      y: 0,
    });

    gsap.to(".link", {
      scrollTrigger: {
        trigger: ".link",
      },
      opacity: 1,
      y: 0,
      duration: 0.75,
      stagger: 0.25,
    });
  }, []);

  return (
    <section
      id="highlights"
      className="w-screen overflow-hidden h-full common-padding bg-zinc"
    >
      <div className="screen-max-width">
        <div className="mb-12 w-full md:flex items-end justify-between">
          <h1
            ref={headingRef}
            id="title"
            className="section-heading"
          >
            Get the highlights.
          </h1>

          <div className="flex flex-wrap items-end gap-5">
            <p className="link">
              Watch the film
              <img
                src={watchImg}
                alt="watch"
                className="ml-2"
              />
            </p>
            <p className="link">
              Watch the event
              <img
                src={rightImg}
                alt="right"
                className="ml-2"
              />
            </p>
          </div>
        </div>

        <VideoCarousel />
      </div>
    </section>
  );
}

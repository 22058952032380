import { Html } from "@react-three/drei";
import React from "react";

const Loader = () => {
  return (
    <Html>
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div
          className="
          w-10 h-10
          border-2 border-gray-200
          rounded-full
          animate-spin
          border-t-2
          border-b-2

        "
        />
      </div>
    </Html>
  );
};

export default Loader;
